// .bot_button{
//   z-index: 1;
//   cursor: pointer;
//   width: 424.31px;
//   height: 348px;
//   position: fixed;
//   bottom: 0px;
//   right: 60px; 
//   background: url('../../public/img/home/right_this_way.png') no-repeat;
//   background-size: 100%;
//   transition: background 0.25s linear, bottom 2.5s linear 0.6s ;
//   border-image: url('../../public/img/home/right_this_way_hover.png') 30; // 占位
  
//   &:hover{
//     // transition: background 0.4s linear 0.2s;
//     background: url('../../public/img/home/right_this_way_hover.png') no-repeat;
//     background-size: 100%;
//     background-position: 0 48px;
//   }
// }
// .bot_button_to_bottom{
//   background: url('../../public/img/home/bot_gump.png') no-repeat;
//   background-size: 100%;
//   // z-index: 999;
//   // transition: background 0.4s , bottom .75s ;
//   bottom: calc(-100vh + -348px);
//   &:hover{
//     background: url('../../public/img/home/bot_gump.png') no-repeat;
//     background-size: 100%;
//   }
// }

// .home_wrapper{
//   height: 100vh;
//   overflow: hidden;
// }
// .bg1{
//   // top: -0vh;
//   position: relative;
//   transition: transform 1s;
//   transform:translateY(-0vh);
// }
// .bg1_to_top{
//   transform:translateY(-100vh);
//   z-index: 1;
//   // position: relative;
// }
// .snow_hole{
//   width: 0vw;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   &_show{
//     width: 100vw;
//   }
// }

// .bg2_door{
//   height: 100vh;
//   position: relative;
//   transition: transform 1s linear 1.25s, top 1s linear 1.25s;
//   background: url('../../public/img/home/door.png') no-repeat;
//   background-size: cover;
//   background-position: center;
//   transform: translateY(0vh);
// }
// .bg2_door_to_top{
//   transform:translateY(-100vh);
// }
// .bg2_door_to_open{
//   top: -100vh;
// }


// .bg2{
//   height: 100vh;
//   top: 0;
//   position: relative;
//   transition: transform 1s linear 1.25s;
//   background: url('../../public/img/home/door_frame.png') no-repeat;
//   background-size: cover;
//   background-position: center;
// }
// .bg2_filter{
//   background: url('../../public/img/home/door_and_frame.png') no-repeat;
//   background-size: cover;
//   position: relative;
//   background-position: center;
//   filter:blur(5px);
//   height: 100%;
//   width: 100%;
//   transition: all 1s;
// }

// .bg2_to_top{
//   top: -100vh;
//   transform:translateY(-100vh);
// }

// .boot_tip_bot{
//   cursor: pointer;
//   background: url('../../public/img/home/boot-tip-bot.png') no-repeat;
//   background-size: cover;
//   height: 816px;
//   width: 623px;
//   position: absolute;
//   bottom: -300px;
//   left: -623px;
//   transition: transform 1s linear 1.5s, left 1s linear 1.5s, background 1s;
// }
// .boot_tip_bot_to_right{
//   left: 0;
//   border-image: url('../../public/img/home/boot-tip-bot-action.png');
//   // transition: transform 1s linear, left 1s linear, background 1s;
// }
// .boot_tip_bot_to_left{
//   left: -623px;
//   transition: transform 1s linear, left 1s linear, background 1s;
// }
// .boot_tip_bot_action, .boot_tip_bot:hover{
//   background: url('../../public/img/home/boot-tip-bot-action.png') no-repeat;
//   background-size: cover;
// }
// .button_wrapper{
//   bottom: 0;
//   left: 484px;
//   position: absolute;
//   background: transparent linear-gradient(64deg, #955E28 0%, #C79015 15%, #DBA40D 42%, #FFFFFF 49%, #FFBB00 60%, #B57E00 84%, #FFDD7E 100%) 0% 0% no-repeat padding-box;
//   box-shadow: 0px 0px 24px #00000058;
//   border-radius: 12px;
//   transition: all 1s;
//   padding: 10px;
//   transition: transform 1s, bottom 0.5s linear 0.75s, height 1s;
//   transform: translateY(100%);
//   &_to_top{
//     transform: translateY(0%);
//     bottom: -16px;
//   }
  
//   .button_content{
//     padding: 16px 24px 12px;
//     background: #03021C 0% 0% no-repeat padding-box;
//     border-radius: 6px;
//     .button_item{
//       cursor: pointer;
//       min-width: 400px;
//       padding: 12px 24px;
//       font: normal normal normal 18px/36px Courier New;
//       background: #03021C 0% 0% no-repeat padding-box;
//       box-shadow: 0px 0px 16px #14E31C00;
//       border: 1px solid #FFFFFF;
//       margin-bottom: 16px;
//       text-align: center;
//       border-radius: 6px;
//       &:hover{
//         background: #03021C 0% 0% no-repeat padding-box;
//         box-shadow: 0px 0px 16px #14E31C;
//         border: 1px solid #14E31C;
//         color: #14E31C;
//         text-shadow: 0px 0px 24px #14E31C;
//       }
//     }
//   }
// }

// .speech_bubble{
//   background: url('../../public/img/home/speech-bubble.svg') no-repeat;
//   background-size: cover;
//   background-position: center;
//   height: 0;
//   width: 508px;
//   position: absolute;
//   left: 400px;
//   bottom: 290px;
//   text-align: left;
//   font: italic normal normal 16px/24px Nexa Slab Bold Italic;
//   letter-spacing: 0px;
//   color: #03021C;
//   // padding: 40px 30px 99px 18px;
//   font-family: typrighter;
//   transition: height 0.5s;
//   overflow: hidden;
// }

// .logo, .logo_to_top{
//   width: 300px;
//   transition: top 0.5s;
//   top:0
// }
// .logo_to_top{
//   position: relative;
//   top: -300px;
// }



// new
.wrapper{
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: url('../../public//img/home/bg.png');
  .fill{
    position: fixed;
    width: 100%;
    height: 100%;
    // background: url('../../public//img/home/bg.png') no-repeat;
    // background-size: cover;
    // background-position: center;
    // transition: background 0.5s linear 0.5s;
    // filter: blur(60px);
    background-color: #000;

  }
  .fill_door{
    width: 100%;
    height: 100%;
    // background: url('../../public//img/home/door_and_frame.png') no-repeat;
    // background-size: cover;
    // background-position: center;
    background-color: #000;
  }
  .content{
    // background-color:  #03021C;
    background: url('../../public/img/home/in-between_snow-and-lab.png') no-repeat;
    background-size: 100%;
    position: relative;
    z-index: 0;
    overflow: hidden;
    .bot_button{
      z-index: 3;
      cursor: pointer;
      position: fixed;
      bottom: 12%;
      right: 2%; 
      background: url('../../public/img/home/right_this_way.png') no-repeat;
      background-size: 100%;
      transition: background 0.25s linear, bottom 3s cubic-bezier(0.305, -0.640, 0.710, 1.690) ;
      border-image: url('../../public/img/home/right_this_way_hover.png') 30; // 占位
      position: absolute;
      width: 34%;
      height: 53%;
      transform: translateY(20%);
      
      &:hover{
        background: url('../../public/img/home/right_this_way_hover.png') no-repeat;
        background-size: 100%;
        background-position: 0 48px;
        border-image: url('../../public/img/home/bot_gump.png') 30; // 占位

      }
    }
    .bot_button_to_bottom{
      background: url('../../public/img/home/bot_gump.png') no-repeat;
      background-size: 100%;
      bottom: -134%;
      &:hover{
        background: url('../../public/img/home/bot_gump.png') no-repeat;
        background-size: 100%;
      }
    }
    

    .christmas_header1{
      width: 65%;
      height: 65%;
      position: absolute;
      top: 0;
      background: transparent url('../../public/img/home/christmas_header1.png') 0% 0% no-repeat padding-box;
      background-size: 100%;
      mix-blend-mode: screen;
    }

    .christmas_header2{
      width: 65%;
      height: 65%;
      position: absolute;
      top: 0;
      background: transparent url('../../public/img/home/christmas_header2.png') 0% 0% no-repeat padding-box;
      background-size: 100%;
      // filter: blur(2px);
    }

    .home_wrapper{
      height: 100vh;
      overflow: hidden;
    }
    .bg1{
      // top: -0vh;
      position: relative;
      transition: transform 1s linear 0.5s;
      transform:translateY(-0);
      height: 100%;
    }
    .bg1_to_top{
      transform:translateY(-100%);
      // z-index: 1;
      // position: relative;
    }
    .snow_hole{
      width: 0%;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: bottom 1s linear 0.5s ;
      z-index: 4;
      &_show{
        width: 100%;
        bottom: 100%;
      }
    }
    .snow_hole_2{
      width: 0%;
      position: absolute;
      bottom: 0;
      transform: translateY(29.6%);
      left: 0;
      transition: bottom 1.2s linear 0.5s, transform 1s linear 0.5s ;
      z-index: 4;
      &_show{
        width: 100%;
        bottom: 100%;
        transform: translateY(0);
      }
    }
    
    .bg2_door{
      height: 100%;
      position: relative;
      transition: transform 1s linear 1.2s, top 1s linear 1.2s;
      background: url('../../public/img/home/door.png') no-repeat;
      background-size: cover;
      background-position: center;
      transform: translateY(0);
    }
    .bg2_door_to_top{
      transform:translateY(-100%);
    }
    .bg2_door_to_open{
      top: -100%;
    }
    
    
    .bg2{
      height: 100%;
      top: 0;
      position: relative;
      transition: transform 1s linear 1.2s;
      background: url('../../public/img/home/door_frame.png') no-repeat;
      background-size: cover;
      background-position: center;
    }
    .bg2_filter{
      background: url('../../public/img/home/door_and_frame.png') no-repeat;
      background-size: cover;
      position: relative;
      background-position: center;
      filter:blur(5px);
      height: 100%;
      width: 100%;
      transition: all 1s;
    }
    
    .bg2_to_top{
      top: -100%;
      transform:translateY(-100%);
    }
    
    .boot_tip_bot{
      cursor: pointer;
      background: url('../../public/img/home/boot-tip-bot.png') no-repeat;
      background-size: cover;
      height: 110%;
      width: 50%;
      position: absolute;
      left: -50%;
      bottom: -35%;
      transition: transform 1s linear 1.5s, left 1s cubic-bezier(0.305, -0.340, 0.710, 1.490) 1.5s, background 1s;
      border-image: url('../../public/img/home/boot-tip-bot-hover.png');
      &:hover{
        background: url('../../public/img/home/boot-tip-bot-hover.png') no-repeat;
        background-size: cover;
      }
    }
    .boot_tip_bot_to_right{
      left: -5%;
      border-image: url('../../public/img/home/boot-tip-bot-action.png');
      // transition: transform 1s linear, left 1s linear, background 1s;
    }
    .boot_tip_bot_to_left{
      left: -623px;
      transition: transform 1s linear, left 1s linear, background 1s;
    }
    .boot_tip_bot_action, .boot_tip_bot_action:hover{
      background: url('../../public/img/home/boot-tip-bot-action.png') no-repeat;
      background-size: cover;
    }
    .button_wrapper{
      bottom: 0;
      left: 35%;
      width: 42%;
      position: absolute;
      background: transparent linear-gradient(64deg, #955E28 0%, #C79015 15%, #DBA40D 42%, #FFFFFF 49%, #FFBB00 60%, #B57E00 84%, #FFDD7E 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 24px #00000058;
      border-radius: 12px;
      transition: all 1s;
      padding: 0.8%;
      transition: transform .6s cubic-bezier(0.305, -0.340, 0.710, 1.490), height 1s;
      transform: translateY(100%);
      &_to_top{
        transform: translateY(14%);
        // bottom: -16px;
      }
      
      .button_content{
        padding: 2% 2% 5%;
        background: #03021C 0% 0% no-repeat padding-box;
        border-radius: 6px;
        .button_item{
          cursor: pointer;
          // min-width: 400px;
          padding: 2% 4%;
          font: normal normal normal 18px/36px Courier New;
          background: #03021C 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 16px #14E31C00;
          border: 1px solid #FFFFFF;
          margin-bottom: 2%;
          text-align: center;
          border-radius: 6px;
          font-size: .15em;
          &:hover{
            background: #03021C 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 16px #14E31C;
            border: 1px solid #14E31C;
            color: #14E31C;
            text-shadow: 0px 0px 24px #14E31C;
          }
        }
      }
    }
    
    .speech_bubble{
      background: url('../../public/img/home/speech-bubble.svg') no-repeat;
      background-size: cover;
      background-position: center;
      height: 0;
      // width: 508px;
      position: absolute;
      left: 29%;
      bottom: 40%;
      text-align: left;
      font: italic normal normal 16px/24px Nexa Slab Bold Italic;
      letter-spacing: 0px;
      color: #03021C;
      // padding: 40px 30px 99px 18px;
      font-family: typrighter;
      transition: height 0.5s;
      overflow: hidden;
      font-size: .12em;
      display: flex;
      // justify-content: center;
      align-items: center;
    }
    
    .logo, .logo_to_top{
      width: 20%;
      transition: top 0.5s;
      top:0
    }
    .logo_to_top{
      position: relative;
      top: -20%;
    }
  }
}